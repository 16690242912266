.App {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.logo {
  /* width: 100px; */
  height: 100px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

h2 {
  color: #57bfca;
}

input {
  font-size: 1em;
  margin: 4px;
}

.loginBtn {
  margin-top: 20px;
  width: 200px;
  /* height: 100px; */
  font-size: 24px;
  font-weight: bold;
  border-radius: 10px;
  background-color: #57bfca;
  color: white;
}
.openBtn {
  width: 200px;
  height: 100px;
  font-size: 24px;
  font-weight: bold;
  border-radius: 10px;
  background-color: #57bfca;
  color: white;
}
.last {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  color: darkgrey;
  text-align: center;
  font-size: x-small;
  line-height: normal;
}

.last p {
  margin-bottom: 0;
}

.progressBar {
  width: 95%;
}
